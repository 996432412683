.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.text {
  display: inline-block;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.arrow {
  position: absolute;
  top: 50%;
  right: -20px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #000;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.link:hover .text {
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}

.link:hover .arrow {
  transform: translate(5px, -50%);
  transition: all 0.3s ease-in-out;
}
