@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'),
    url(./config/fonts/Ubuntu-Regular.ttf) format('truetype');
}

code {
  font-family: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: linear-gradient(360deg, #a6bdc8 5.84%, #ffffff 100%) fixed !important;
}

p {
  font-family: 'Ubuntu', sans-serif !important;
}
div {
  font-family: 'Ubuntu', sans-serif !important;
}

span {
  font-family: 'Ubuntu', sans-serif !important;
}

button {
  font-family: 'Ubuntu', sans-serif !important;
}

a {
  font-family: 'Ubuntu', sans-serif !important;
}

.MuiCardActions-root > :not(:first-of-type) {
  margin-left: 0px !important;
}
